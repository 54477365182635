.product-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  .info-summary {
    display: flex;
    flex-wrap: wrap;
  }
}

.card {
  // border: 1px solid #f4f6f6;
  // border-bottom: 3px solid var(--light-blue);
  padding: 5px;
  background-color: #fff;
}

.card1 {
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.card2 {
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.card3 {
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.card4 {
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
