.add-donation {
  margin-bottom: 5rem;
  .card {
    width: 100%;
    max-width: 600px;
    padding: 10px;
  }
  form {
    label {
      display: block;
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--color-dark);
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    select,
    input[type="password"] {
      display: block;
      font-size: 1.2rem;
      font-weight: 400;
      padding: 10px;
      width: 100%;
      border-radius: 3px;
      outline: none;
      border: none;
    }
  }
}

.group {
  // border: 1px solid var(--dark-blue);
  padding: 5px;
}

.image-preview {
  width: 100%;
  height: 100%;
  max-height: 280px;
  // background: var(--dark-blue);
  padding: 10px;
  border-radius: 3px;
  overflow: hidden;
  // display: none;
  transition: all 0.3s;
}

.image-preview img {
  width: 100%;
}

.image-show {
  display: block;
}

.donation-form{
  display: flex;
  flex-direction: column;
  gap:16px;
}