.product-detail {
  margin-bottom: 5rem;
  width: 100%;
  max-width: 1000px;
  
  .card {
    width: 100%;
    max-width: 1000px;
    padding: 1rem;

  }
  .detail {
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: flex-start;
    p b {
      color: var(--dark-blue);
    }

    .product-image-container{
      height: 300px;
      width: auto;

      img{
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .admin-controls{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:20px;
    .button {
      display: flex;
      gap:10px;
    }
  }
}

.group {
  // border: 1px solid var(--dark-blue);
  padding: 5px;
}


.tag{
    padding:10px;
    line-height: 1;
    // margin: 0px;
    // color: #fff;
    font-weight: 600;
    // text-align: center;
    // border-radius: 15px;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.good{
  color: #000;
}

.service{
  color: #8a2be2;
}

.claimed{
  color: #008000;
}

.unclaimed{
  color: #ff0000;
}