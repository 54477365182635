.donation-detail {
  margin-bottom: 5rem;
  width: 100%;
  max-width: 1000px;
  
  .card {
    width: 100%;
    max-width: 1000px;
    padding: 1rem;

  }
  .detail {
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: flex-start;
    p b {
      color: var(--dark-blue);
    }

    .donation-image-container{
      height: 300px;
      width: auto;

      img{
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.group {
  // border: 1px solid var(--dark-blue);
  padding: 5px;
}

